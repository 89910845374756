import Footer from "../../others/footer/footer";
import Navbar from "../../others/navbar/navbar";
import Search from "../../others/search/search";
import './landing_page.css'
const LandingPage = ()=>{

    return (
       <>
       <div id="hero-section-header">
         <Navbar/>
        <div className="hero-section-body">
        <div class="banner-title"> {'Find your perfect Teacher '}  </div>
        <div  class="search-bar"> <Search/> </div> 
        </div>
       </div>
       </>
    )
}


export default LandingPage;