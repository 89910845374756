import { tutors } from './tutors'
import TutorInfo from '../tutor_info/tutor_info';

const renderList = (data) => data.map((e,i) => <TutorInfo key={i} tutorData = {e}/> )   

const TutorList = () =>{

    return (
        <>
           {renderList(tutors)}
        </>
    )
}

export default TutorList;