import { useState, useEffect } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../../environment/apis_key' 

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const useFirebaseAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log(user)
      if (user) {
        setCurrentUser(user);
        setIsLogin(true);
      } else {
        setCurrentUser(null);
        setIsLogin(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Google Sign-In Error:", error.message);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setIsLogin(false);
      setCurrentUser(null);
      console.log('logout success')
    } catch (error) {
      console.error("Logout Error:", error.message);
    }
  };

  return {
    loginWithGoogle,
    isLogin,
    logout,
    currentUserDetail: currentUser
  };
};

export default useFirebaseAuth;
