import './not_found.css'






const NotFound = () =>{
    return (
        <div className="display">
            notfound
        </div>
    )
}


export default NotFound;