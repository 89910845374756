import React from 'react';
import './footer.css'; // Make sure to import your CSS file for styling

const Footer = () => {
  return (
    <footer>
      <div className="bottom-details">
        <div className="bottom_text">
          <span className="copyright_text">
            Copyright © 2024 <a>ADVERSITY.in</a> All rights reserved
          </span>
          <span className="policy_terms">
            <a>Privacy policy</a>
            <a>Terms & condition</a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
