import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import  LandingPage  from './components/marketing/landing_page/landing_page'
import FindTutor from './components/product/find_tutor/find_tutor'
import  NotFound from './components/others/not_found'

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/find-tutor" element={<FindTutor/>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Router>
  );
}

export default App;
