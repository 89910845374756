import { useEffect } from "react"
import './tutor_info.css'

const TutorInfo = (tutorData)=>{


    useEffect(()=>{
        console.log(tutorData)
    } ,[])

   return (<>
   <div className="d-flex">
    
  
     <div className="tutor-info-parent d-flex">
        <div className="tutor-pic me-4"> <span id="super-tutor"><i class="fa-solid fa-bolt pe-1"></i>{'Super Tutor'}</span> <img className="teacher-img" src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT30xiL7VH8TOrjOhDE3F7aRJbGyw0TE-ELFTTSb3mOL-JGrwoFAtC0j9nH2OJvuyS9tx0&usqp=CAU'}/></div>
        <div className="d-flex">
            <div className="middle-content mx-1">
            <div id="tutor-name">{tutorData['tutorData']['first_name'] + ' ' + tutorData['tutorData']['last_name']} </div>
             <div className="general-info">
                 <div id="tutor-subjects"> <i class="fa-solid fa-graduation-cap me-2"></i> <span> {tutorData['tutorData']['subject_you_teach'].map((e)=> e).join(', ')} </span></div>
                 <div id="language-speak"> <i class="fa-solid fa-language me-2"></i> <span> {tutorData['tutorData']['languages_spoken'].map((e)=> e).join(', ')} </span></div>
             </div>
            <div id="profile-summary"><strong> {'Tutor with ' + tutorData['tutorData']['teaching_experience'] + ' of experience'} </strong> <span> {' - ' +tutorData['tutorData']['introduce_yourself'] }</span> </div>
            </div>
            <div className="side-content">
                <div className="d-flex"> 
                    <div className="tutor-rating d-flex flex-column mx-1"> <span> <i style={{color:'#FFBF00'}} class="fa-solid fa-star"></i> <span style={{fontWeight:'600'}}>{5}</span> </span> <span style={{fontSize:'12px'}} className="general-info">{'(15 reviews)'}</span> </div> 
                    <div className="tutor-pricing mx-1"><strong>{tutorData['tutorData']['pricing']['currency'].toUpperCase() +' '+ tutorData['tutorData']['pricing']['rate']}</strong><span style={{fontSize:'12px'}} className="general-info">{' / ' +tutorData['tutorData']['pricing']['frequency']}</span></div>
                </div>
                
                <div className="mt-2 mx-2">
                   <div> <button className="expand-profile py-2 px-4 my-1"> <i class="fa-solid fa-eye me-2"></i>{'View More'} </button> </div>
                   <div> <button className="start-tutor-chat py-2 px-3 my-1"><i class="fa-solid fa-message me-2"></i>{'Send Message'}</button> </div>
                </div>
            </div>
        </div>
     </div>
     <div>
     </div>
   </div>
    
    </>)
}

export default TutorInfo