import { useEffect, useState } from "react";
import Suggestion from "../suggestion-box/suggestion";
import "./search.css";
import { useNavigate } from 'react-router-dom';
import useLocationAndAreaDetails from '../../utils/fetch_user_location'
import useFirebaseAuth from '../../utils/login_with_google'


const Search = () => {
  const [subject, setSubject] = useState("");
  const [location, setLocation] = useState("");
  const [focusedInput, setFocusedInput] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const { userLocation, areaDetails, error  ,getUserLocation } = useLocationAndAreaDetails();
  const navigate = useNavigate();
  const { loginWithGoogle, isLogin, logout, currentUserDetail } = useFirebaseAuth();

  const searchLocation = async (value)=>{
    setLocation(value)
  }
  
  const handleRedirectAndFindTutors = () => {
    if (isLogin) {
      navigate('/find-tutor');
    } else {
      loginWithGoogle().then(() => {
        navigate('/find-tutor');
      }).catch((error) => {
        console.error('Login failed:', error);
      });
    }
  };


  useEffect(()=>{
    console.log(areaDetails)
    error==null  && areaDetails?.address['state_district'] != undefined ?  setLocation( areaDetails?.address['state_district'] + ',' +  areaDetails?.address['state']) : setLocation('')
    },[userLocation, areaDetails, error ,isLogin])

  return (
    <>
      <div className="search">
        <div className="typeof-search-container">
          {" "}
          <span>{"Search Around"}</span>{" "}
        </div>
        <div className="search-container">
          {" "}
          <i className="fa-solid fa-location-dot"></i>
          <input
            onChange={(e) =>searchLocation(e.target.value) }
            value={location}
            onFocus={() => {
              setFocusedInput("location");
              getUserLocation()
            }}
            id="location"
            type="text"
            placeholder="Allow Location"
          />{" "}
          <input
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
            onFocus={() => {
              setFocusedInput("subject");
            }}
            id="subject"
            type="text"
            placeholder="📚 Search Subject"
          />
          
          {" "}
          <button onClick={handleRedirectAndFindTutors} id="search-button">Search</button>{" "}
        </div>
        <div className="Suggestion-box">
          {" "}
          {/* <Suggestion
            items={suggestionList}
            search={focusedInput == "location" ? location : subject}
            setSearch={focusedInput == "location" ? setLocation : setSubject}
          />{" "} */}
        </div>
      </div>
    </>
  );
};

export default Search;
