export const find_location_api = 'https://nominatim.openstreetmap.org/search?q='  
//   'https://nominatim.openstreetmap.org/search?q={locaton}&format=json'


export const firebaseConfig ={
    apiKey: "AIzaSyC-shPt-b70aS8JgPKnZ_4WzfUbpPAaGVY",
  authDomain: "adversity-ui.firebaseapp.com",
  projectId: "adversity-ui",
  storageBucket: "adversity-ui.appspot.com",
  messagingSenderId: "1085277970136",
  appId: "1:1085277970136:web:ad7c750fad78e1e0cc01ad",
  measurementId: "G-SH3T43CZEQ"
  }