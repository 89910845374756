export const tutors = [
    {
      first_name: 'Amit',
      last_name: 'Sharma',
      email: 'amit.sharma@example.com',
      country_of_birth: 'India',
      subject_you_teach: ['Mathematics', 'Physics'],
      languages_spoken: ['Hindi', 'English'],
      mobile_number: '9876543210',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'Delhi University',
          degree: 'B.Sc.',
          specialization: 'Mathematics',
          year_of_study: { start: '2010', end: '2013' }
        }
      ],
      introduce_yourself: 'I am passionate about teaching and helping students excel in Mathematics.',
      teaching_experience: '10 years',
      motivate_potential_student: 'I believe every student can achieve greatness with the right guidance.',
      profile_headline: 'Experienced Math and Physics Tutor',
      pricing: { frequency: 'hr', rate: '500', currency: 'inr' },
      tutor_location: { city: 'New Delhi', state: 'Delhi' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Sara',
      last_name: 'Williams',
      email: 'sara.williams@example.com',
      country_of_birth: 'USA',
      subject_you_teach: ['English Literature', 'Creative Writing'],
      languages_spoken: ['English', 'Spanish'],
      mobile_number: '9123456780',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'Harvard University',
          degree: 'M.A.',
          specialization: 'English Literature',
          year_of_study: { start: '2011', end: '2013' }
        }
      ],
      introduce_yourself: 'A passionate writer and teacher with a love for literature.',
      teaching_experience: '8 years',
      motivate_potential_student: 'Let’s explore the beauty of words and stories together.',
      profile_headline: 'Literature and Writing Expert',
      pricing: { frequency: 'hr', rate: '1000', currency: 'inr' },
      tutor_location: { city: 'New York', state: 'New York' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Priya',
      last_name: 'Singh',
      email: 'priya.singh@example.com',
      country_of_birth: 'India',
      subject_you_teach: ['Biology', 'Chemistry'],
      languages_spoken: ['Hindi', 'English'],
      mobile_number: '9988776655',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'AIIMS',
          degree: 'M.Sc.',
          specialization: 'Biochemistry',
          year_of_study: { start: '2015', end: '2017' }
        }
      ],
      introduce_yourself: 'Dedicated to making science fun and accessible.',
      teaching_experience: '5 years',
      motivate_potential_student: 'Science is all around us. Let’s explore it together!',
      profile_headline: 'Science Enthusiast and Educator',
      pricing: { frequency: 'hr', rate: '600', currency: 'inr' },
      tutor_location: { city: 'Mumbai', state: 'Maharashtra' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'John',
      last_name: 'Doe',
      email: 'john.doe@example.com',
      country_of_birth: 'Canada',
      subject_you_teach: ['History', 'Geography'],
      languages_spoken: ['English', 'French'],
      mobile_number: '8765432109',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'University of Toronto',
          degree: 'B.A.',
          specialization: 'History',
          year_of_study: { start: '2008', end: '2012' }
        }
      ],
      introduce_yourself: 'History is not just about the past; it’s about understanding the present.',
      teaching_experience: '12 years',
      motivate_potential_student: 'Let’s journey through time and uncover the stories that shaped our world.',
      profile_headline: 'Passionate History and Geography Teacher',
      pricing: { frequency: 'hr', rate: '700', currency: 'inr' },
      tutor_location: { city: 'Toronto', state: 'Ontario' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Lina',
      last_name: 'Martinez',
      email: 'lina.martinez@example.com',
      country_of_birth: 'Mexico',
      subject_you_teach: ['Spanish', 'Art'],
      languages_spoken: ['Spanish', 'English'],
      mobile_number: '9023456789',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'National Autonomous University of Mexico',
          degree: 'B.A.',
          specialization: 'Spanish Literature',
          year_of_study: { start: '2012', end: '2016' }
        }
      ],
      introduce_yourself: 'Art and language are my passions, and I love sharing them with students.',
      teaching_experience: '6 years',
      motivate_potential_student: 'Let’s discover the beauty of the Spanish language and the world of art together.',
      profile_headline: 'Spanish and Art Teacher',
      pricing: { frequency: 'hr', rate: '550', currency: 'inr' },
      tutor_location: { city: 'Mexico City', state: 'Mexico City' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Wei',
      last_name: 'Chen',
      email: 'wei.chen@example.com',
      country_of_birth: 'China',
      subject_you_teach: ['Chinese', 'Mathematics'],
      languages_spoken: ['Chinese', 'English'],
      mobile_number: '8734567890',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'Peking University',
          degree: 'M.Sc.',
          specialization: 'Applied Mathematics',
          year_of_study: { start: '2013', end: '2015' }
        }
      ],
      introduce_yourself: 'Mathematics is a universal language, and I am here to help you understand it.',
      teaching_experience: '7 years',
      motivate_potential_student: 'With patience and practice, you can master any mathematical concept.',
      profile_headline: 'Mathematics and Chinese Tutor',
      pricing: { frequency: 'hr', rate: '450', currency: 'inr' },
      tutor_location: { city: 'Beijing', state: 'Beijing' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Sophia',
      last_name: 'Brown',
      email: 'sophia.brown@example.com',
      country_of_birth: 'UK',
      subject_you_teach: ['Physics', 'Chemistry'],
      languages_spoken: ['English'],
      mobile_number: '9981234567',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'University of Oxford',
          degree: 'Ph.D.',
          specialization: 'Physics',
          year_of_study: { start: '2010', end: '2014' }
        }
      ],
      introduce_yourself: 'Science is fascinating, and I love making it accessible to everyone.',
      teaching_experience: '9 years',
      motivate_potential_student: 'Let’s unlock the secrets of the universe together.',
      profile_headline: 'Physics and Chemistry Specialist',
      pricing: { frequency: 'hr', rate: '900', currency: 'inr' },
      tutor_location: { city: 'London', state: 'England' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Arjun',
      last_name: 'Patel',
      email: 'arjun.patel@example.com',
      country_of_birth: 'India',
      subject_you_teach: ['Computer Science', 'Mathematics'],
      languages_spoken: ['Gujarati', 'Hindi', 'English'],
      mobile_number: '9812345678',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'IIT Bombay',
          degree: 'B.Tech',
          specialization: 'Computer Science',
          year_of_study: { start: '2007', end: '2011' }
        }
      ],
      introduce_yourself: 'Technology and mathematics are my passions, and I enjoy teaching both.',
      teaching_experience: '8 years',
      motivate_potential_student: 'Together, we can explore the wonders of technology and solve complex problems.',
      profile_headline: 'Computer Science and Mathematics Educator',
      pricing: { frequency: 'hr', rate: '750', currency: 'inr' },
      tutor_location: { city: 'Ahmedabad', state: 'Gujarat' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Maria',
      last_name: 'Gomez',
      email: 'maria.gomez@example.com',
      country_of_birth: 'Spain',
      subject_you_teach: ['Spanish', 'History'],
      languages_spoken: ['Spanish', 'English'],
      mobile_number: '9123456789',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'University of Barcelona',
          degree: 'M.A.',
          specialization: 'History',
          year_of_study: { start: '2010', end: '2012' }
        }
      ],
      introduce_yourself: 'History and language are my strengths, and I love sharing them with students.',
      teaching_experience: '7 years',
      motivate_potential_student: 'Understanding history helps us understand ourselves. Let’s dive into it together.',
      profile_headline: 'Spanish Language and History Tutor',
      pricing: { frequency: 'hr', rate: '600', currency: 'inr' },
      tutor_location: { city: 'Barcelona', state: 'Catalonia' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Liam',
      last_name: 'Smith',
      email: 'liam.smith@example.com',
      country_of_birth: 'Australia',
      subject_you_teach: ['Geography', 'Environmental Science'],
      languages_spoken: ['English'],
      mobile_number: '9876543210',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'University of Sydney',
          degree: 'B.Sc.',
          specialization: 'Environmental Science',
          year_of_study: { start: '2009', end: '2012' }
        }
      ],
      introduce_yourself: 'The earth is our home, and I am here to teach you all about it.',
      teaching_experience: '10 years',
      motivate_potential_student: 'Let’s explore the beauty of our planet and learn how to protect it.',
      profile_headline: 'Geography and Environmental Science Educator',
      pricing: { frequency: 'hr', rate: '800', currency: 'inr' },
      tutor_location: { city: 'Sydney', state: 'New South Wales' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Olivia',
      last_name: 'Jones',
      email: 'olivia.jones@example.com',
      country_of_birth: 'New Zealand',
      subject_you_teach: ['English', 'Drama'],
      languages_spoken: ['English', 'Maori'],
      mobile_number: '9234567890',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'University of Auckland',
          degree: 'M.A.',
          specialization: 'English Literature',
          year_of_study: { start: '2012', end: '2014' }
        }
      ],
      introduce_yourself: 'Literature and drama are my passions, and I love bringing stories to life.',
      teaching_experience: '6 years',
      motivate_potential_student: 'Let’s explore the world of literature and drama together.',
      profile_headline: 'English and Drama Teacher',
      pricing: { frequency: 'hr', rate: '650', currency: 'inr' },
      tutor_location: { city: 'Auckland', state: 'Auckland' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Ethan',
      last_name: 'Lee',
      email: 'ethan.lee@example.com',
      country_of_birth: 'South Korea',
      subject_you_teach: ['Korean', 'Computer Science'],
      languages_spoken: ['Korean', 'English'],
      mobile_number: '9871234560',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'Seoul National University',
          degree: 'B.Sc.',
          specialization: 'Computer Science',
          year_of_study: { start: '2011', end: '2015' }
        }
      ],
      introduce_yourself: 'Technology is the future, and I am here to help you understand it.',
      teaching_experience: '8 years',
      motivate_potential_student: 'Together, we can navigate the complex world of technology.',
      profile_headline: 'Korean Language and Computer Science Tutor',
      pricing: { frequency: 'hr', rate: '750', currency: 'inr' },
      tutor_location: { city: 'Seoul', state: 'Seoul' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Emma',
      last_name: 'Davis',
      email: 'emma.davis@example.com',
      country_of_birth: 'USA',
      subject_you_teach: ['Psychology', 'Sociology'],
      languages_spoken: ['English'],
      mobile_number: '9009876543',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'Stanford University',
          degree: 'Ph.D.',
          specialization: 'Psychology',
          year_of_study: { start: '2008', end: '2012' }
        }
      ],
      introduce_yourself: 'Understanding the mind and society is my passion, and I love teaching it to others.',
      teaching_experience: '12 years',
      motivate_potential_student: 'Let’s explore the complexities of human behavior together.',
      profile_headline: 'Psychology and Sociology Expert',
      pricing: { frequency: 'hr', rate: '1000', currency: 'inr' },
      tutor_location: { city: 'San Francisco', state: 'California' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Daniel',
      last_name: 'Garcia',
      email: 'daniel.garcia@example.com',
      country_of_birth: 'Argentina',
      subject_you_teach: ['Spanish', 'Economics'],
      languages_spoken: ['Spanish', 'English'],
      mobile_number: '9345678901',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'University of Buenos Aires',
          degree: 'M.A.',
          specialization: 'Economics',
          year_of_study: { start: '2013', end: '2015' }
        }
      ],
      introduce_yourself: 'Economics is more than just numbers; it’s about understanding the world.',
      teaching_experience: '7 years',
      motivate_potential_student: 'Let’s explore the world of economics and learn how it shapes our lives.',
      profile_headline: 'Spanish and Economics Tutor',
      pricing: { frequency: 'hr', rate: '800', currency: 'inr' },
      tutor_location: { city: 'Buenos Aires', state: 'Buenos Aires' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Isabella',
      last_name: 'Martinez',
      email: 'isabella.martinez@example.com',
      country_of_birth: 'Colombia',
      subject_you_teach: ['Biology', 'Environmental Science'],
      languages_spoken: ['Spanish', 'English'],
      mobile_number: '9456789012',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'National University of Colombia',
          degree: 'B.Sc.',
          specialization: 'Biology',
          year_of_study: { start: '2010', end: '2014' }
        }
      ],
      introduce_yourself: 'Biology is the study of life, and I am passionate about teaching it.',
      teaching_experience: '8 years',
      motivate_potential_student: 'Together, we can explore the wonders of life on earth.',
      profile_headline: 'Biology and Environmental Science Educator',
      pricing: { frequency: 'hr', rate: '700', currency: 'inr' },
      tutor_location: { city: 'Bogota', state: 'Bogota' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Noah',
      last_name: 'Wilson',
      email: 'noah.wilson@example.com',
      country_of_birth: 'South Africa',
      subject_you_teach: ['History', 'Political Science'],
      languages_spoken: ['English', 'Zulu'],
      mobile_number: '9567890123',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'University of Cape Town',
          degree: 'M.A.',
          specialization: 'Political Science',
          year_of_study: { start: '2008', end: '2010' }
        }
      ],
      introduce_yourself: 'History and politics are intertwined, and I love exploring both.',
      teaching_experience: '9 years',
      motivate_potential_student: 'Let’s delve into history and politics to understand the world today.',
      profile_headline: 'History and Political Science Tutor',
      pricing: { frequency: 'hr', rate: '850', currency: 'inr' },
      tutor_location: { city: 'Cape Town', state: 'Western Cape' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Sophia',
      last_name: 'Wong',
      email: 'sophia.wong@example.com',
      country_of_birth: 'China',
      subject_you_teach: ['Mandarin', 'Art'],
      languages_spoken: ['Mandarin', 'English'],
      mobile_number: '9678901234',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'Tsinghua University',
          degree: 'B.A.',
          specialization: 'Fine Arts',
          year_of_study: { start: '2007', end: '2011' }
        }
      ],
      introduce_yourself: 'Art and language are my tools for expressing creativity.',
      teaching_experience: '5 years',
      motivate_potential_student: 'Let’s explore the world of art and language together.',
      profile_headline: 'Mandarin Language and Art Teacher',
      pricing: { frequency: 'hr', rate: '700', currency: 'inr' },
      tutor_location: { city: 'Beijing', state: 'Beijing' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'James',
      last_name: 'Brown',
      email: 'james.brown@example.com',
      country_of_birth: 'USA',
      subject_you_teach: ['Physics', 'Mathematics'],
      languages_spoken: ['English'],
      mobile_number: '9789012345',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'Massachusetts Institute of Technology',
          degree: 'Ph.D.',
          specialization: 'Physics',
          year_of_study: { start: '2005', end: '2010' }
        }
      ],
      introduce_yourself: 'Physics and mathematics are the languages of the universe, and I love teaching them.',
      teaching_experience: '10 years',
      motivate_potential_student: 'Let’s explore the mysteries of the universe through physics and math.',
      profile_headline: 'Physics and Mathematics Tutor',
      pricing: { frequency: 'hr', rate: '900', currency: 'inr' },
      tutor_location: { city: 'Boston', state: 'Massachusetts' },
      available_within: 0.6,
      currently_active: 'Active'
    },
    {
      first_name: 'Charlotte',
      last_name: 'Lee',
      email: 'charlotte.lee@example.com',
      country_of_birth: 'Australia',
      subject_you_teach: ['Chemistry', 'Biology'],
      languages_spoken: ['English'],
      mobile_number: '9890123456',
      profile_pic_base64: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...',
      Education: [
        {
          university: 'University of Melbourne',
          degree: 'M.Sc.',
          specialization: 'Chemistry',
          year_of_study: { start: '2011', end: '2013' }
        }
      ],
      introduce_yourself: 'Chemistry and biology are the foundations of life, and I love teaching them.',
      teaching_experience: '8 years',
      motivate_potential_student: 'Let’s explore the building blocks of life through chemistry and biology.',
      profile_headline: 'Chemistry and Biology Educator',
      pricing: { frequency: 'hr', rate: '850', currency: 'inr' },
      tutor_location: { city: 'Melbourne', state: 'Victoria' },
      available_within: 0.6,
      currently_active: 'Active'
    }
  ]

  export const subjects = [
    { value: '1', label: 'Mathematics' },
    { value: '2', label: 'Physics' },
    { value: '3', label: 'English' },
    { value: '4', label: 'Creative Writing' },
    { value: '5', label: 'Biology' },
    { value: '6', label: 'Chemistry' },
    { value: '7', label: 'History' },
    { value: '8', label: 'Geography' },
    { value: '9', label: 'Spanish' },
    { value: '10', label: 'Art' },
    { value: '11', label: 'Chinese' },
    { value: '12', label: 'Computer Science' },
    { value: '13', label: 'Drama' },
    { value: '14', label: 'Korean' },
    { value: '15', label: 'Psychology' },
    { value: '16', label: 'Sociology' },
    { value: '17', label: 'Environmental Science' },
    { value: '18', label: 'Economics' }
  ]

  export const mode = [
    {
    value: 'offline',
    label: 'Offline',
  },
  {
    value: 'online',
    label: 'Online',
    disabled: true,
  }
]


export const pricing = [
  {
  value: 'low',
  label: 'Low To High',
},
{
  value: 'high',
  label: 'high To Low',
}
]


export const languages = [
  {
  value: 'offline',
  label: 'Offline',
},
{
  value: 'online',
  label: 'Online',
  disabled: true,
}
]
  