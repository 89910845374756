import { useState } from 'react';
import axios from 'axios';

const useLocationAndAreaDetails = () => {
  const [userLocation, setUserLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [areaDetails, setAreaDetails] = useState(null);
  const [error, setError] = useState(null);

  // Function to get user location using Geolocation API
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onLocationSuccess, onLocationError);
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  // Handle success case when location is retrieved
  const onLocationSuccess = async (position) => {
    const { latitude, longitude } = position.coords;
    setUserLocation({ latitude, longitude });
    
    // Reverse geocoding to get area details
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;
    try {
      const response = await axios.get(apiUrl);
      setAreaDetails(response.data);
    } catch (err) {
      setError("Failed to fetch area details.");
    }
  };

  // Handle geolocation error
  const onLocationError = (error) => {
    setError(error.message || "An error occurred while retrieving location.");
  };

  // Return state variables and the function to manually trigger location fetching
  return { userLocation, areaDetails, error, getUserLocation };
};

export default useLocationAndAreaDetails;
