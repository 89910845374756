import './navbar.css'
import  useFirebaseAuth from '../../utils/login_with_google'
import { useEffect } from 'react';
import { Avatar} from 'antd';
const Navbar = () =>{

    const { loginWithGoogle, isLogin, logout, currentUserDetail } = useFirebaseAuth();

    useEffect(()=>{
       console.log(isLogin)
       console.log(currentUserDetail)
       console.log(currentUserDetail?.photoURL)
    },[isLogin])

    return(
        <>
        <div id='navbar-container'>
        <div class="logo-container">
         <span class="logo-text">{'ADVERSITY.in'}</span>
         </div>
         <div>
           { isLogin ? <div onClick={logout} className='mx-2'><Avatar src={<img src={currentUserDetail?.photoURL} alt="avatar" />} /> </div> :  <button onClick={()=> loginWithGoogle()} className='login-btn'>Login</button> }           
         </div>
        </div>
        
      
        </>
    )
}

export default Navbar;