import { useState } from "react";
import TutorFilter from "./tutor_filter/tutor_filter";
import TutorList from "./tutor_list/tutor_list"
import { Watermark } from 'antd';
import {mode , subjects , pricing , languages} from "./tutor_list/tutors"

const FindTutor = ()=>{
    const [tutorFilter , setTutorFilter] = useState({languages:languages,mode:mode , subjects:subjects , pricing:pricing})

    return (
        <Watermark  content="">
             <div  className="">
            {/* <TutorFilter filterValues={tutorFilter} setFilterValues = {setTutorFilter} /> */}
            <div className="m-2">
            <TutorList/>
            </div>
        </div>
       </Watermark>
       
    )
}


export default FindTutor;